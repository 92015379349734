import React, { useEffect } from "react"
import { navigate } from "gatsby"

const Admin = () => {
  useEffect(() => {
    window.location.replace("https://admin.wspolnota120.pl/admin")
  }, [])

  return <></>
}

export default Admin
